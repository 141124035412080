<template>
  <a-modal v-model:visible="innerVisible"
           title="活码详情"
           width="700px"
           :footer="null"
           @cancel="handleCancle">

    <a-spin :spinning="dataLoading">
      <div class="flex">
        <div class="px-32 flex flex-col justify-center items-center"
             style="width: 220px;">
          <img :src="liveCodeDetail.avatar"
               alt="渠道活码图"
               style="width: 120px; height: 120px" />

          <a-tooltip v-if="liveCodeDetail.name?.length >= 20"
                     class="my-16 line-clamp-2 cursor-pointer"
                     :title="liveCodeDetail.name">
            {{ liveCodeDetail.name }}
          </a-tooltip>
          <span v-else
                class="my-16 line-clamp-2">
            {{ liveCodeDetail.name }}
          </span>

          <a-button class="w-full h-32"
                    type="primary"
                    @click="downloadImg(liveCodeDetail.avatar, liveCodeDetail.name)">
            下载活码</a-button>
        </div>

        <a-divider type="vertical"
                   style="height: inherit;" />

        <div class="flex-1 overflow-y-auto"
             style="max-height: 420px;">

          <span class="inline-block mr-16 w-84 text-right text-color-666">创建时间：</span>
          <span class="text-color-333">{{ $f.datetime(liveCodeDetail.createdTime, 'YYYY-MM-DD HH:mm') }}</span>

          <div class="flex my-20">
            <span class="flex-shrink-0 inline-block mr-16 w-84 text-right text-color-666">使用成员：</span>
            <div class="flex flex-wrap">
              <avatar-column v-for="staff in liveCodeDetail.staffs"
                             :key="staff.corpStaffId"
                             class="py-8 px-12 mr-8 mb-4 bg-color-f5 border border-radius"
                             style="width: 140px;"
                             :avatar="{avatar: staff.avatar, userId: staff.workWechatThirdAppUserId }"
                             :size="32"
                             is-user-id />
            </div>
          </div>

          <div v-if="liveCodeDetail.tags?.length"
               class="flex">
            <span class="flex-shrink-0 inline-block mr-16 w-84 text-right text-color-666">客户标签：</span>
            <div class="flex flex-wrap">
              <a-tag v-for="tag in liveCodeDetail.tags"
                     :key="tag.workWechatTagId">{{ tag.name }}</a-tag>
            </div>
          </div>

          <div class="my-20">
            <span class="inline-block mr-16 w-84 text-right text-color-666">欢迎语状态：</span>
            <span class="text-color-333 ">{{ liveCodeDetail.welcomeSpeechEnabled ? '开启' : '关闭' }}</span>
            <span v-if="liveCodeDetail.welcomeSpeechEnabled"
                  class="ml-12 color-primary cursor-pointer"
                  @click="isPreview = true">点击预览</span>
          </div>
        </div>
      </div>
    </a-spin>

    <section v-if="isPreview"
             class="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70"
             style="z-index: 1000;"
             @click="isPreview = false">
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
           @click.stop>
        <phone-box>
          <li v-if="liveCodeDetail.welcomeSpeech.content"
              class="reply-list__item">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang" />
            <div v-html="innerHtml"
                 class="msg-text">
            </div>
          </li>

          <li v-for="(content, index) in liveCodeDetail.welcomeSpeech.contents"
              :key="index"
              class="reply-list__item">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang" />
            <template v-if="content.type === appendixTypeEnum.IMAGE">
              <a-image class="msg-img"
                       :src="content.bucketUri"
                       alt="欢迎语附件图片" />
            </template>
            <template v-else-if="content.type === appendixTypeEnum.LINK">
              <a :href="content.link"
                 target="_blank">
                <msg-link :msg="{title: content.title, subTitle: content.summary, img: content.cover}" />
              </a>
            </template>
            <template v-else-if="content.type === appendixTypeEnum.FILE">
              <a :href="content.bucketUri"
                 target="_blank">
                <msg-link :msg="{title: content.name, subTitle: renderSize(content.size), img: pdfImgUrl}" />
              </a>
            </template>
          </li>
        </phone-box>

        <svg-icon class="absolute -right-24 text-32 text-color-f cursor-pointer"
                  style="top: -2%"
                  type="iconshanchu3"
                  @click="isPreview = false" />
      </div>
    </section>
  </a-modal>
</template>

<script>
import _ from "lodash";
import { reactive, ref, watch } from "vue";
import { Image, Tooltip } from "ant-design-vue";

import SvgIcon from "@/components/SvgIcon";
import MsgLink from "@/components/MsgLink";
import PhoneBox from "@/components/PhoneBox";

import { appendixTypeEnum, renderSize, downloadImg } from "@/global";

import ChannelQrcode from "@/service/api/channelQrcode";
import AvatarColumn from "@/components/AvatarColumn.vue";

export default {
  name: "ChannelModalDetail",

  components: {
    AImage: Image,
    ATooltip: Tooltip,

    SvgIcon,
    MsgLink,
    PhoneBox,
    AvatarColumn,
  },

  props: {
    //活码的id
    id: Number,
    visible: Boolean,
  },

  emits: ["update:visible"],

  setup(props, { emit }) {
    const dataLoading = ref(true);

    const innerVisible = ref(false);

    watch(
      () => props.visible,
      (newVisible) => {
        innerVisible.value = newVisible;

        getCodeDetail();
      }
    );

    const liveCodeDetail = reactive({
      name: "",
      avatar: "",
      createdTime: "",
      tags: [],
      welcomeSpeechEnabled: undefined,
      welcomeSpeech: {
        content: "",
        contents: [],
      },
    });

    const clearCodeDeatil = () => {
      dataLoading.value = true;
      _.assign(liveCodeDetail, {
        name: "",
        avatar: "",
        createdTime: "",
        tags: [],
        welcomeSpeechEnabled: undefined,
        welcomeSpeech: {
          content: "",
          contents: [],
        },
      });
    };

    const innerHtml = ref("");

    const getCodeDetail = async () => {
      const res = await ChannelQrcode.get(props.id);

      _.assign(liveCodeDetail, res);

      if (liveCodeDetail.welcomeSpeech.content) {
        innerHtml.value = formatContentToInnerHTML();
      }

      dataLoading.value = false;
    };

    const formatContentToInnerHTML = () => {
      const nickNameReg = new RegExp("{nickName}", "g");
      const staffNameReg = new RegExp("{staffName}", "g");

      let innerHTML = "";
      innerHTML = _.replace(
        liveCodeDetail.welcomeSpeech.content,
        nickNameReg,
        '<span class="text-keyword mx-4" style="color: #ff761d">客户昵称</span>'
      );
      innerHTML = _.replace(
        innerHTML,
        staffNameReg,
        '<span class="text-keyword mx-4" style="color: #ff761d">员工姓名</span>'
      );
      return innerHTML;
    };

    const handleCancle = () => {
      emit("update:visible", false);
      clearCodeDeatil();
    };

    const isPreview = ref(false);

    return {
      appendixTypeEnum,
      renderSize,
      downloadImg,

      pdfImgUrl: process.env.VUE_APP_PDF_IMAGE_URL,

      liveCodeDetail,

      isPreview,
      innerVisible,
      dataLoading,
      innerHtml,

      handleCancle,
    };
  },
};
</script>