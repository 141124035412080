<template>
  <section class="phone-box">
    <img src="@/assets/phone.png"
         alt="手机聊天背景图">
    <div class="phone-box__content">
      <ul class="reply-list">
        <slot></slot>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "PhoneBox",

  props: {},

  data() {
    return {};
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>